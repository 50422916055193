import React from "react";

const GridLoading = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-[4rem] justify-center items-center w-[70vw] m-auto my-[2rem]">
      {new Array(4).fill(null).map((_, index) => (
        <div
          key={index}
          className="shadow-[gray] p-8 rounded-2xl flex flex-col gap-4 min-h-[10rem] bg-[#aaa] shadow-none animate-pulse"
        ></div>
      ))}
    </div>
  );
};

export default GridLoading;
