import { Link } from "react-router-dom";
import logo from "../assets/images/logo192.png";
import { useState } from "react";

const Ribbon = () => {
  const [menu, setMenu] = useState(false);

  return (
    <nav className="flex gap-[8rem] justify-evenly items-center py-2 px-4">
      <Link to="/" className="text-[3rem] text-khamco-primary no-underline font-minimoBold">
        <img src={logo} alt="" style={{ width: "40%" }} />
      </Link>
      {!menu && <button className="text-khamco-ribbon text-[2rem] cursor-pointer md:hidden" onClick={() => setMenu(!menu)}>
        <i className="fas fa-bars"></i>
      </button>}
      {menu && <button className="text-khamco-ribbon text-[2rem] cursor-pointer md:hidden" onClick={() => setMenu(!menu)}>
        <i className="fas fa-times" ></i>
      </button>}
      <ul className={`md:flex flex-col md:flex-row justify-between list-none gap-[4rem] absolute md:relative w-full md:w-[15%] bg-white top-[15%] z-50 py-[1rem] px-[4rem] md:p-0 ${
          menu ? "flex" : "hidden"
        }`}>
        <Link to="/" className="text-white bg-khamco-ribbon py-2 px-4 rounded-2xl cursor-pointer text-[1rem]">Main Menu</Link>
      </ul>
    </nav>
  );
};

export default Ribbon;
