import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="text-center p-[4rem] bg-khamco-ribbon text-white">
      <h4 className="text-[2rem] my-[1rem] mx-0 font-minimoBold">
        {new Date().getFullYear()} &copy; Kham.co
      </h4>
      <address className="my-[2rem] mx-0">{t("Kuwait")}</address>
      <nav>
        <ul className="flex flex-col gap-[1rem]">
          <li>
            <Link
              to="/"
              className="text-white no-underline hover:underline cursor-pointer"
            >
              {t("Main Menu")}
            </Link>
          </li>
          <li>
            <Link
              to="/customers"
              className="text-white no-underline hover:underline cursor-pointer"
            >
              {t("Customers")}
            </Link>
          </li>
          <li>
            <Link
              to="/admin/dashboard"
              className="text-white no-underline hover:underline cursor-pointer"
            >
              {t("Administration")}
            </Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;
