import { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { hasRoles } from "../utilities";

const Home = () => {
  const authKey = localStorage.getItem("jwt");
  const navigate = useNavigate();
  const user = useStoreState((state) => state.user);
  const { t } = useTranslation();

  const isTokenExpired = useCallback((token) => {
    const payloadBase64 = token.split(".")[1];
    const decodedJson = atob(payloadBase64);
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp;
    const now = Date.now().valueOf() / 1000;
    return now > exp;
  }, []);

  useEffect(() => {
    document.title = "Khamco Shop Home";

    const isLoggedIn = () => {
      const token = authKey;
      return token != null && !isTokenExpired(token);
    };

    if (!isLoggedIn()) {
      localStorage.clear();
      navigate("/users/login");
    }

    if (!user) {
      toast.warn(t("You are not authorized to access that resource."));
    }
  }, [isTokenExpired, authKey, navigate, user, t]);

  return (
    <>
      <Navbar />
      <main className="p-[1rem] md:p-[4rem] w-[90%] m-auto font-minimoMedium">
        <h1 className="text-center text-[2rem] font-minimoBold">
          {t("Main Menu")}
        </h1>
        <div className="flex justify-center items-center my-[3rem] flex-wrap w-full">
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-12 justify-center min-h-screen">
            {hasRoles(["Administrator", "Measurer", "Driver"], user) && (
              <Link
                to="/customers"
                className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff] h-[25vh] md:h-[40vh]"
              >
                <i className="fas fa-users"></i>
                <p>{t("Customers")}</p>
              </Link>
            )}
            {hasRoles(
              ["Administrator", "Measurer", "Cutter", "Driver"],
              user
            ) && (
              <Link
                to="/products"
                className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff] h-[25vh] md:h-[40vh]"
              >
                <i className="fas fa-box-archive"></i>
                <p>{t("Inventory")}</p>
              </Link>
            )}

            {/* Tailor Dashboard: Always show */}
            <Link
              to={`/users/my-dashboard/${user?.id}`}
              className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff] h-[25vh] md:h-[40vh]"
            >
              <i className="fas fa-dashboard"></i>
              <p>{t("My Dashboard")}</p>
            </Link>
            {hasRoles(["Administrator", "Measurer", "Driver"], user) && (
              <Link
                to="/services"
                className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff] h-[25vh] md:h-[40vh]"
              >
                <i className="fas fa-shirt"></i>
                <p>{t("Services")}</p>
              </Link>
            )}
            {hasRoles(["Administrator", "Measurer"], user) && (
              <Link
                to="/invoices"
                className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff] h-[25vh] md:h-[40vh]"
              >
                <i className="fas fa-receipt"></i>
                <p>{t("Invoices")}</p>
              </Link>
            )}
            {hasRoles(["Administrator"], user) && (
              <Link
                to="/orders"
                className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff] h-[25vh] md:h-[40vh]"
              >
                <i className="fas fa-bookmark"></i>
                <p>{t("Orders")}</p>
              </Link>
            )}
            {hasRoles(["Administrator"], user) && (
              <Link
                to="/admin/dashboard"
                className="flex flex-col items-center justify-center gap-2 p-8 rounded-3xl bg-[#0000ffcc] text-white text-lg hover:bg-[#0000ff] h-[25vh] md:h-[40vh]"
              >
                <i className="fas fa-ban"></i>
                <p>{t("Administration")}</p>
              </Link>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Home;
