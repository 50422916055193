// Clothing options
import shirt from "./assets/images/clothing/shirt.png";
import jacket from "./assets/images/clothing/jacket.png";
import hoodie from "./assets/images/clothing/hoodie.png";
import suit from "./assets/images/clothing/suit.png";
import pants from "./assets/images/clothing/pants.png";
import shorts from "./assets/images/clothing/shorts.png";
import dress from "./assets/images/clothing/dress.png";
import thawb from "./assets/images/clothing/thawb.png";
import sleepwear from "./assets/images/clothing/sleepwear.png";
import waistcoat from "./assets/images/clothing/waistcoat.png";
import coat from "./assets/images/clothing/coat.png";
import coverall from "./assets/images/clothing/coveralls.png";
import scissors from "./assets/images/clothing/scissors.png";
import special from "./assets/images/clothing/special.png";
import fashion from "./assets/images/clothing/fashion.png";
import sweater from "./assets/images/clothing/sweater.png";
// Measurement icons
import dishdashaLengthIcon from "./assets/images/icons/dishdasha-length.png";
import armHole from "./assets/images/icons/arm-hole.png";
import backHeight from "./assets/images/icons/back-height.png";
import backWidth from "./assets/images/icons/back-width.png";
import belly from "./assets/images/icons/belly.png";
import bicepTricep from "./assets/images/icons/bicep-tricep.png";
import calf from "./assets/images/icons/calf.png";
import chest from "./assets/images/icons/chest.png";
import chestHeight from "./assets/images/icons/chest-height.png";
import chestWidth from "./assets/images/icons/chest-width.png";
import coatLength from "./assets/images/icons/coat-length.png";
import collar from "./assets/images/icons/collar.png";
import dressLengthIcon from "./assets/images/icons/dress-length.png";
import elbow from "./assets/images/icons/elbow.png";
import extras from "./assets/images/icons/extras.png";
import hips from "./assets/images/icons/hips.png";
import inseam from "./assets/images/icons/inseam.png";
import knees from "./assets/images/icons/knees.png";
import leg from "./assets/images/icons/leg.png";
import other from "./assets/images/icons/other.png";
import pantsLength from "./assets/images/icons/pants-length.png";
import shirtLength from "./assets/images/icons/shirt-length.png";
import shortsLengthIcon from "./assets/images/icons/shorts-length.png";
import shoulders from "./assets/images/icons/shoulders.png";
import sleevesLong from "./assets/images/icons/sleeve-long-sleeve.png";
import sleevesShort from "./assets/images/icons/sleeve-short-sleeve.png";
import thighs from "./assets/images/icons/thighs.png";
import unknown from "./assets/images/icons/unknown.png";
import userDefined from "./assets/images/icons/user-defined.png";
import vestLength from "./assets/images/icons/vest-length.png";
import waist from "./assets/images/icons/waist.png";
import wrist from "./assets/images/icons/wrist.png";

export const measurement_icons = {
  dishdashaLengthIcon: dishdashaLengthIcon,
  armHole: armHole,
  backHeight: backHeight,
  backWidth: backWidth,
  belly: belly,
  bicepTricep: bicepTricep,
  calf: calf,
  chest: chest,
  chestHeight: chestHeight,
  chestWidth: chestWidth,
  coatLength: coatLength,
  collar: collar,
  dressLengthIcon: dressLengthIcon,
  elbow: elbow,
  extras: extras,
  hips: hips,
  inseam: inseam,
  knees: knees,
  leg: leg,
  other: other,
  pantsLength: pantsLength,
  shirtLength: shirtLength,
  shortsLengthIcon: shortsLengthIcon,
  shoulders: shoulders,
  sleevesLong: sleevesLong,
  sleevesShort: sleevesShort,
  thighs: thighs,
  unknown: unknown,
  userDefined: userDefined,
  vestLength: vestLength,
  waist: waist,
  wrist: wrist,
};

// Reusable functions for the measurements page
export const clothingList = [
  { label: "Dishdasha", imageUrl: thawb },
  { label: "Shirt", imageUrl: shirt },
  { label: "Classic Jacket", imageUrl: jacket },
  { label: "Hoodie", imageUrl: hoodie },
  { label: "Sweater", imageUrl: sweater },
  { label: "Suit", imageUrl: suit },
  { label: "Pants", imageUrl: pants },
  { label: "Shorts", imageUrl: shorts },
  { label: "Dress", imageUrl: dress },
  { label: "Sleepwear", imageUrl: sleepwear },
  { label: "Coat", imageUrl: coat },
  { label: "Vest", imageUrl: waistcoat },
  { label: "Coveralls", imageUrl: coverall },
  { label: "Other", imageUrl: fashion },
  { label: "Alterations", imageUrl: scissors },
  { label: "Special Order", imageUrl: special },
];

export const hasShirt = (list) => {
  return list.some((item) => item.clothing_item === "Shirt");
};

export const isSuit = (list) => {
  return list.some((item) => item.clothing_item === "Suit");
};

export const isVest = (list) => {
  return list.some((item) => item.clothing_item === "Vest");
};
export const isCoat = (list) => {
  return list.some((item) => item.clothing_item === "Vest");
};

export const dishdashaUnits = (list) => {
  return (
    list.some((item) => item.clothing_item === "Dishdasha") ||
    list.some((item) => item.clothing_item === "Sleepwear")
  );
};

export const garmentLength = (list) => {
  return (
    list.some((item) => item.clothing_item === "Shirt") ||
    list.some((item) => item.clothing_item === "Sweater") ||
    list.some((item) => item.clothing_item === "Hoodie") ||
    list.some((item) => item.clothing_item === "Suit") ||
    list.some((item) => item.clothing_item === "Coat") ||
    list.some((item) => item.clothing_item === "Vest") ||
    list.some((item) => item.clothing_item === "Classic Jacket")
  );
};

export const isShorts = (list) => {
  return list.some((item) => item.clothing_item === "Shorts");
};

export const isDress = (list) => {
  return list.some((item) => item.clothing_item === "Dress");
};

export const hasSpecialOrder = (list) => {
  return list.some((item) => item.clothing_item === "Special Order");
};

export const upperMeasurements = (list) => {
  return (
    list.some((item) => item.clothing_item === "Dishdasha") ||
    list.some((item) => item.clothing_item === "Sweater") ||
    list.some((item) => item.clothing_item === "Classic Jacket") ||
    list.some((item) => item.clothing_item === "Hoodie") ||
    list.some((item) => item.clothing_item === "Suit") ||
    list.some((item) => item.clothing_item === "Dress") ||
    list.some((item) => item.clothing_item === "Sleepwear") ||
    list.some((item) => item.clothing_item === "Coat") ||
    list.some((item) => item.clothing_item === "Vest") ||
    list.some((item) => item.clothing_item === "Coveralls") ||
    list.some((item) => item.clothing_item === "Other") ||
    list.some((item) => item.clothing_item === "Shirt")
  );
};

export const lowerMeasurements = (list) => {
  return (
    list.some((item) => item.clothing_item === "Suit") ||
    list.some((item) => item.clothing_item === "Pants") ||
    list.some((item) => item.clothing_item === "Shorts") ||
    list.some((item) => item.clothing_item === "Dress") ||
    list.some((item) => item.clothing_item === "Sleepwear") ||
    list.some((item) => item.clothing_item === "Coat") ||
    list.some((item) => item.clothing_item === "Coveralls") ||
    list.some((item) => item.clothing_item === "Other")
  );
};

export const isCoverall = (list) => {
  return list.some((item) => item.clothing_item === "Coveralls");
};

export const hasSleepwearSleeve = (list) => {
  return list.some((item) => item.clothing_item === "Sleepwear");
};

export const hasAlterations = (list) => {
  return list.some((item) => item.clothing_item === "Alterations");
};

// Helper function to dynamically display area names
export function selectArea(mainElement, setGovernorate, setAreas) {
  setGovernorate(mainElement.value);
  switch (mainElement.value) {
    case "Al Aasima":
      setAreas([
        "Abdulla Al-Salem",
        "Adailiya",
        "Bnaid Al-Qar",
        "Daʿiya",
        "Dasma",
        "Doha",
        "Doha Port",
        "Faiha'",
        "Failaka",
        "Granada",
        "Jaber Al-Ahmad City",
        "Jibla",
        "Kaifan",
        "Khaldiya",
        "Mansūriya",
        "Mirgab",
        "Nahdha",
        "North West Sulaibikhat",
        "Nuzha",
        "Qadsiya",
        "Qurtuba",
        "Rawda",
        "Shamiya",
        "Sharq",
        "Shuwaikh",
        "Shuwaikh Industrial Area",
        "Shuwaikh Port",
        "Sulaibikhat",
        "Surra",
        "Umm an Namil Island",
        "Yarmouk",
      ]);
      break;
    case "Hawalli":
      setAreas([
        "Anjafa",
        "Bayān",
        "Bi'da",
        "Hawally",
        "Hittin",
        "Jabriya",
        "Maidan Hawalli",
        "Mishrif",
        "Mubarak Al-Jabir",
        "Nigra",
        "Rumaithiya",
        "Salam",
        "Salmiya",
        "Salwa",
        "Sha'ab",
        "Shuhada",
        "Siddiq",
        "South Surra",
        "Zahra",
      ]);
      break;
    case "Mubarak Al-Kabeer":
      setAreas([
        "Abu Al Hasaniya",
        "Abu Futaira",
        "Adān",
        "Al Qurain",
        "Al-Qusour",
        "Fintās",
        "Funaitīs",
        "Misīla",
        "Mubarak Al-Kabeer",
        "Sabah Al-Salem",
        "Sabhān",
        "South Wista",
        "Wista",
      ]);
      break;
    case "Ahmadi":
      setAreas([
        "Abu Halifa",
        "Abdullah Port",
        "Ahmadi",
        "Ali As-Salim",
        "Aqila",
        "Bar Al Ahmadi",
        "Bneidar",
        "Dhaher",
        "Fahaheel",
        "Fahad Al-Ahmad",
        "Hadiya",
        "Jaber Al-Ali",
        "Jawaher Al Wafra",
        "Jilei'a",
        "Khairan",
        "Mahbula",
        "Mangaf",
        "Miqwa'",
        "New Khairan City",
        "New Wafra",
        "Nuwaiseeb",
        "Riqqa",
        "Sabah Al-Ahmad City",
        "Sabah Al Ahmad Sea City",
        "Sabahiya",
        "Shu'aiba (North & South)",
        "South Sabahiya",
        "Wafra",
        "Zoor",
        "Zuhar",
      ]);
      break;
    case "Farwaniya":
      setAreas([
        "Abdullah Al-Mubarak",
        "Airport District",
        "Andalous",
        "Ardiya",
        "Ardiya Herafiya",
        "Ardiya Industrial Area",
        "Ashbelya",
        "Dhajeej",
        "Farwaniya",
        "Fordous",
        "Jleeb Al-Shuyoukh",
        "Khaitan",
        "Omariya",
        "Rabiya",
        "Rai",
        "Al-Riggae",
        "Rihab",
        "Sabah Al-Nasser",
        "Sabaq Al Hajan",
      ]);
      break;
    case "Jahra":
      setAreas([
        "Abdali",
        "Al Nahda / East Sulaibikhat",
        "Amghara",
        "Bar Jahra",
        "Jahra",
        "Jahra Industrial Area",
        "Kabad",
        "Naeem",
        "Nasseem",
        "Oyoun",
        "Qasr",
        "Saad Al Abdullah City",
        "Salmi",
        "Sikrab",
        "South Doha / Qairawān",
        "Subiya",
        "Sulaibiya",
        "Sulaibiya Agricultural Area",
        "Taima",
        "Waha",
      ]);
      break;
    default:
      setAreas([]);
  }
}

// Helper function to apply different animations
export function applyAnimation(orderStatus) {
  switch (orderStatus) {
    case "Not Started":
      return "bg-[black] text-[white] animate-pulse";
    case "Measuring In Progress":
      return "bg-[yellow] animate-pulse";
    case "Cutting In Progress":
      return "bg-[yellow] animate-pulse";
    case "Tailoring In Progress":
      return "bg-[yellow] animate-pulse";
    case "Finishing Tailoring In Progress":
      return "bg-[yellow] animate-pulse";
    case "Driving In Progress":
      return "bg-[yellow] animate-pulse";
    case "QA/QC In Progress":
      return "bg-[yellow] animate-pulse";
    case "Measuring Complete":
      return "bg-[lightgreen] animate-pulse";
    case "Cutting Complete":
      return "bg-[lightgreen] animate-pulse";
    case "Tailoring Complete":
      return "bg-[lightgreen] animate-pulse";
    case "Finishing Tailoring Complete":
      return "bg-[lightgreen] animate-pulse";
    case "QA/QC Complete":
      return "bg-[lightgreen] animate-pulse";
    case "Driving Complete":
      return "bg-[lightgreen] animate-pulse";
    case "Measurer Assigned":
      return "bg-[orange] animate-pulse";
    case "Cutting Assigned":
      return "bg-[orange] animate-pulse";
    case "Driver Assigned":
      return "bg-[orange] animate-pulse";
    case "Measurer Accepted":
      return "bg-[orange] animate-pulse";
    case "Cutter Accepted":
      return "bg-[orange] animate-pulse";
    case "Tailor Accepted":
      return "bg-[orange] animate-pulse";
    case "Finishing Tailor Accepted":
      return "bg-[orange] animate-pulse";
    case "Driver Accepted":
      return "bg-[orange] animate-pulse";
    case "Tailoring Assigned":
      return "bg-[orange] animate-pulse";
    case "Finishing Tailor Assigned":
      return "bg-[orange] animate-pulse";
    case "QA/QC Assigned":
      return "bg-[orange] animate-pulse";
    case "Ready for Pick Up":
      return "bg-[orange] animate-pulse";
    case "Measurer Rejected":
      return "bg-[red] text-[white] animate-pulse";
    case "Cutter Rejected":
      return "bg-[red] text-[white] animate-pulse";
    case "Tailor Rejected":
      return "bg-[red] text-[white] animate-pulse";
    case "Finishing Tailor Rejected":
      return "bg-[red] text-[white] animate-pulse";
    case "Driver Rejected":
      return "bg-[red] text-[white] animate-pulse";
    case "Requires Alteration":
      return "bg-[red] text-[white] animate-pulse";
    case "Cancelled":
      return "bg-[red] text-[white] animate-pulse";
    case "Order Delivered":
      return "bg-[green] text-[white] animate-pulse";
    case "Collected From Shop":
      return "bg-[skyblue] text-[black] animate-pulse";
    default:
      return "";
  }
}

// Array containing are names
export const areaNames = [
  "Abdulla Al-Salem",
  "Adailiya",
  "Bnaid Al-Qar",
  "Daʿiya",
  "Dasma",
  "Doha",
  "Doha Port",
  "Faiha'",
  "Failaka",
  "Granada",
  "Jaber Al-Ahmad City",
  "Jibla",
  "Kaifan",
  "Khaldiya",
  "Mansūriya",
  "Mirgab",
  "Nahdha",
  "North West Sulaibikhat",
  "Nuzha",
  "Qadsiya",
  "Qurtuba",
  "Rawda",
  "Shamiya",
  "Sharq",
  "Shuwaikh",
  "Shuwaikh Industrial Area",
  "Shuwaikh Port",
  "Sulaibikhat",
  "Surra",
  "Umm an Namil Island",
  "Yarmouk",
];

// Helper function to determine if the user lacks specific roles
export const lacksRoles = (roles, user) => {
  return !user?.role?.some((r) => roles.includes(r.type));
};

// Helper function to determine if the user has specific roles
export const hasRoles = (roles, user) => {
  return user?.role?.some((r) => roles.includes(r.type));
};
